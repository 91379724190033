<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-icon
          class="no-print print-preview"
          @click="print()"
      >mdi-printer</v-icon>
      <v-icon
          class="no-print close-preview"
          @click="goBack()"
      >mdi-close-circle</v-icon>
      <div class="preview-inner">
        <v-row class="mt-0">
          <v-col class="col-8"></v-col>
          <v-col class="col-4">
            <img width="180px" src="../../assets/logo.png" alt="" />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="col-8 address-corner">
            <p>
              {{ survey.customer }}<br />
              <span v-if="survey.customerAddressOne">{{ survey.customerAddressOne }}, <br /></span>
              <span v-if="survey.customerAddressTwo">{{ survey.customerAddressTwo }}, <br /></span>
              <span v-if="survey.customerAddressThree">{{ survey.customerAddressThree }}, <br /></span>
              <span v-if="survey.customerAddressFour">{{ survey.customerAddressFour }}, <br /></span>
              <span v-if="survey.customerAddressFive">{{ survey.customerAddressFive }}, <br /></span>
              <span v-if="survey.customerPostcode">{{ survey.customerPostcode }}</span>
            </p>
          </v-col>
          <v-col class="col-4">
            <p>
              Unit X Lodge Lane Industrial Estate<br />
              Tuxford, Newark, Notts NG22 0NL
            </p>
            <p>
              Office: 0800 8488414
              <br />
              Email: info@riggott.co.uk
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="col-8">
            <h2 class="large-heading pb-4">Works Survey</h2>
            <h4>Date: {{ survey.prettyDate }}</h4>
          </v-col>
        </v-row>
        <v-row :key="index" v-for="(section, index) in surveySections">
          <v-col class="col-4">
            <p>Item #{{ (index + 1) }}</p>
            <p>{{ section.title}}</p>
            <p>{{ section.description }}</p>
          </v-col>
          <v-col class="col-8">
            <v-row>
              <v-col
                  class="col-3"
                  :key="findex"
                  v-for="(file, findex) in section.files">
                <img :src="file.uri" alt="" />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12">
            <hr class="mt-4" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ViewSurvey',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      survey: {
        address: '',
        customer: '',
        prettyDate: '',
        id: 0,
        requestor: '',
        surveyor: '',
      },
      surveySections: [],
    };
  },
  methods: {
    print() {
      window.print();
    },
    goBack() {
      this.$router.go('-1');
    },
    getSurvey() {
      axios.get(`/siteSurveys/getOne/${this.$route.params.id}.json?token=${this.token}`)
          .then((response) => {
            this.survey = response.data.survey;
          });
    },
    getSurveySections() {
      axios.get(`/siteSurveySections/get/${this.$route.params.id}.json?token=${this.token}`)
          .then((response) => {
            this.surveySections = response.data.sections;
          });
    },
  },
  mounted() {
    this.getSurvey();
    this.getSurveySections();
  },
};
</script>

<style lang="scss" scoped>
.terms-heading {
  fonts-size: 14px;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .preview-inner {
    margin: 10mm 5mm 10mm 5mm;
  }
  .numbered-para {
    font-size: 10px;
    margin-bottom: 11px;
  }
  .text-small {
    font-size: 14px;
  }
  .print-no-top-marg {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
